import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'SignIn',
      component: () => import('../components/SignIn.vue')
    },
    {
      path: '/pages',
      name: 'Pages',
      component: () => import('../views/Pages.vue')
    },
    {
      path: '/category',
      name: 'Category',
      component: () => import('../views/Category.vue')
    },
    {
      path: '/products',
      name: 'Products',
      component: () => import('../views/Products.vue')
    },
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/gallery',
      name: 'Gallery',
      component: () => import ('../views/Gallery.vue')
    },
  ]
})

export default router
