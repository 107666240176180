<template>
  <div id="app">
    <div v-if="event === 'SIGNED_IN'" class="container">
      <navigation />
      <router-view />
    </div>
    <div v-else class="container">
      <sign-in />
    </div>
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import SignIn from "./components/SignIn.vue";

export default {
  components: { Navigation, SignIn },
  data() {
    return {
      event: "",
    };
  },

  created() {
    this.getSession();
  },

  methods: {
    getSession() {
      this.$supabase.auth.onAuthStateChange(
        (event, session) => {
          this.event = event
        }
      )
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}
</style>
