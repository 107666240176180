<template>
  <div class="row">
    <div class="col-md-12">
      <header class="mt-5 mb-5">
        <h3>binaroom(private)</h3>
      </header>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="signIn">
            <div class="form-group">
              <input type="email" v-model="email" class="form-control" autofocus autocomplete="off" placeholder="email">
            </div>
            <div class="form-group">
              <input ref="password" type="password" v-model="password" class="form-control" placeholder="password" :disabled="!validEmail">
            </div>
            <button class="btn btn-dark" :disabled="password < 1">Sign In</button>
          </form>
        </div>
        <div class="bg-danger card-footer" v-if="err">
          email or password are mismatch!
          <button @click="err = false" class="btn btn-light btn-sm">X</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        email: '',
        password: '',
        err: false,
        validEmail: false,
      }
    },

    methods: {
      async signIn() {
        let { user, session, error } = await this.$supabase.auth.signIn({
          email: this.email,
          password: this.password
        })

        if (user) {
          this.$router.push('/home')
        }

        if(error) this.err = true
      },
    },

    watch: {
      email(val) {
        let mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@gmail.com$/;
        if (val.match(mailFormat)) {
          this.validEmail = true
          setTimeout(() => {
            this.$refs.password.focus()
          }, 100);
        }
      }
    }
  }
</script>

<style scoped>

</style>