<template>
  <div class="row">
    <div class="col-md-12 text-dark">
      <div class="card pt-4 pb-4">
        <div class="card-body">
          <h3 class="text-center">hello, welcome back! 😀</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
a {
  color: #2f2f2f;
  text-decoration: underline;
}
a:hover {
  color: #5f5f5f;
}
p {
  font-size: 24px;
}
</style>
