import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/style.css"

import VueSupabase from 'vue-supabase'

Vue.use(VueSupabase, {
  supabaseKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYzODQ4ODI4MiwiZXhwIjoxOTU0MDY0MjgyfQ.wu8WQnl-kS1Ee5PBkdsBaF0h-VRwzWQZXk69usbwyD4",
  supabaseUrl: "https://dsryzuznqufyhlnodxyi.supabase.co",
  supabaseOptions: {},
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
