<template>
  <div class="row">
    <div class="col-md-12">
      <nav class="nav text-center pt-3 pb-3">
        <router-link to="/home" class="nav-link">home.</router-link>
        <!-- <router-link to="/category" class="nav-link">category.</router-link> -->
        <router-link to="/gallery" class="nav-link">gallery.</router-link>
        <router-link to="/products" class="nav-link">products.</router-link>
        <!-- <router-link to="/pages" class="nav-link">pages.</router-link> -->
        <a @click="signOut" class="nav-link text-danger"><strong>(sign out)</strong></a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async signOut() {
      let { error } = await this.$supabase.auth.signOut()
      if(error) console.error(error)
      else this.$router.push('/')
    }
  }
};
</script>

<style scoped>
nav .nav-link {
  font-size: 1.9vh;
}
a {
  transition: all .3s ease;
  cursor: pointer;
}
a:hover {
  color: inherit;
  transform: rotateZ(-10deg);
}
</style>
